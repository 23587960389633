import Head from "next/head";
import type { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { datadogRum } from "@datadog/browser-rum";
import { ConfigProvider } from "antd";

import apolloClient from "api/apollo-client";

import "styles/globals.scss";
import "styles/ag-grid-override.css";
import "styles/variables.scss";
import "styles/colors.scss";

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || "",
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || "",
  site: "datadoghq.com",
  service: process.env.NEXT_PUBLIC_DATADOG_SERVICE || "",
  env: process.env.NEXT_PUBLIC_DATADOG_ENV || "",
  // Specify a version number to identify the deployed version of your application in Datadog
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

export default function App({ Component, pageProps }: AppProps) {
  const googleKey = process.env.NEXT_PUBLIC_GOOGLE_AUTH_KEY || "";
  const titlePrefix = process.env.NEXT_PUBLIC_TITLE_PREFIX || "";

  return (
    <>
      <Head>
        <title>{titlePrefix + "GC Data Management Tool"}</title>
        <meta name="description" content="GC Data Management Tool" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Archivo:wght@100;300;400;600&family=Roboto&display=swap"
          rel="stylesheet"
        />
      </Head>
      <ApolloProvider client={apolloClient}>
        <GoogleOAuthProvider clientId={googleKey}>
          <ConfigProvider theme={{ token: { fontFamily: "Archivo" } }}>
            <Component {...pageProps} />
          </ConfigProvider>
        </GoogleOAuthProvider>
      </ApolloProvider>
    </>
  );
}
