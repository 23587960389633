import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import logger from "lib/logger";
import typeDefs from "./schema";

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    "access-control-allow-origin": "*",
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("authToken");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      // , locations, path
      if (message === "AUTH_ERROR") {
        logger.error("Authentication error. Redirecting to auth page.");
        window.location.href = "/";
      }
    });

  if (networkError) {
    logger.error("Network error.", { networkError });
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  typeDefs,
});

export default client;
